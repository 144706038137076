@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Anton';
  font-weight: 400;
  src: local('Inter'), url(./fonts/Anton-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: local('Inter'), url(./fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(./fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

@layer base {
	body {
		background-color: black
	}
}

